<template>
	<div id="DragDropFile">
		<div class="box__drag">
			<div class="box__text">
				<div>Subir imágenes o archivos asociados al problema {{subtype && subtype.flag_file? '(Obligatorio)':'(opcional)'}}</div>
			</div>

			<input
				ref="fileInput"
				type="file"
				name="dragDropInputFile"
				id="dragDropInputFile"
				v-bind="attrs"
				@change="handleFileChange"
			/>
		</div>

		<div>
			<v-chip
				v-for="(file, index) in files"
				:key="index + '-chip'"
				color="primary"
				class="ma-2"
				outlined
				close
				@click:close="() => deleteFile(index)"
			>
				{{ file?.name }}
			</v-chip>
		</div>
	</div>
</template>

<script>
import { $EventBus } from '@/main';
export default {
	name: 'DragDropFile',
	props: {
		value: {
			type: Array,
			required: true,
		},
		attrs: {
			type: Object,
			required: false,
		},
		maxsize: {
			type: Number,
			required: false,
		},
		extensions: {
			type: Array,
			required: false,
		},
		subtype:{
			type: Object,
			required:false,
		},
	},
	data() {
		return {};
	},
	computed: {
		files: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		deleteFile(index) {
			this.files.splice(index, 1);
		},
		handleFileChange() {
			const files = this.$refs.fileInput?.files;
			for (let i = 0; i < files.length; i++) {
				if (this.validfile(files[i])) {
					this.files.push(files[i]);
				}
			}
			this.$refs.fileInput.value = null;
		},

		isFileSizeValid(file, maxSize) {
			const maxSizeBytes = maxSize * 1024 * 1024;
			return file?.size < maxSizeBytes;
		},

		isFileExtensionValid(file, allowedExtensions) {
			const nombreArchivo = file?.name || '';
			const extensionArchivo = nombreArchivo.slice(
				((nombreArchivo.lastIndexOf('.') - 1) >>> 0) + 2
			);
			return allowedExtensions.includes('.' + extensionArchivo.toLowerCase());
		},

		validfile(file) {
			let isvalid = true;
			if (this.maxsize) {
				if (!this.isFileSizeValid(file, this.maxsize)) {
					isvalid = false;
					$EventBus.$emit(
						'showSnack',
						'warning',
						`El archivo excede el tamaño máximo permitido. (${this.maxsize}MB)`
					);
				}
			}
			if (
				this.extensions &&
				Array.isArray(this.extensions) &&
				this.extensions.length
			) {
				if (!this.isFileExtensionValid(file, this.extensions)) {
					isvalid = false;
					$EventBus.$emit(
						'showSnack',
						'warning',
						`La extensión de archivo no es válida (${this.extensions.join(
							', '
						)})`
					);
				}
			}
			return isvalid;
		},
	},
};
</script>

<style lang="scss">
#DragDropFile {
	.box__drag {
		height: 150px;
		width: 100%;
		position: relative;
		border-radius: 10px;
		overflow: hidden;
		cursor: pointer;
		.box__text {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: white;
			pointer-events: none;
			display: flex;
			justify-content: center;
			align-items: center;
			border: solid lightblue;
			border-style: dotted;
		}
		input {
			display: block;
			width: 100%;
			height: 100%;
			cursor: pointer;
		}
	}
}
</style>
